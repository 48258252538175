import Sortable from 'stimulus-sortable'

export default class extends Sortable {
  static targets = ["imageOrder"]; // target to store ordered IDs

  onUpdate(event) {
    super.onUpdate(event)

    const orderedIds = $('.sortable-image').map(function() {
      return $(this).data('id');
    }).get().join(',');
    
    this.imageOrderTarget.value = orderedIds;
  }
}

