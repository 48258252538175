import CheckboxSelectAll from "stimulus-checkbox-select-all"

// stimulus controller for bulk action checkbox selection
export default class extends CheckboxSelectAll {
  static targets = super.targets.concat(['actionBtn', 'selectedCount', 'selectedCountContainer']);

  static values = {
    url: { type: String, default: '/search_selections' },
    ajaxMethod: { type: String, default: 'POST' },
    paramsChecksum: String,
    selectedIds: Array
  }

  connect() {
    super.connect();
    this.toggleBulkActionButton();
  }

  toggle(e) {
    super.toggle(e);

    this.updateSelectAll(e);
  }

  toggleBulkActionButton() {
    const count = this.checked.length;
    this.selectedCountTarget.textContent = count;
    if(count > 0) {
      this.actionBtnTarget.classList.remove('disabled');
      this.selectedCountContainerTarget.classList.remove("disabled");
    } else {
      this.actionBtnTarget.classList.add('disabled');
      this.selectedCountContainerTarget.classList.add("disabled")
    }
  }

  updateSelectAll(e) {
    this.toggleBulkActionButton();
    let data = new FormData();
    let ids = []
    if(e.target.checked) {
      this.checked.forEach( (checkbox) => {
        data.append(checkbox.name, checkbox.value)
        ids.push(checkbox.value)
      });
    }
    this.selectedIdsValue = ids;
    this.updateOnServer(data,'set');
  }

  updateSelectOne(e) {
    if(e.type == 'input') {
      // do nothing if event programmatically fired by CheckboxSelectAll component
      return
    }
    this.toggleBulkActionButton();
    let data = new FormData();
    data.append(e.target.name, e.target.value);
    this.updateOnServer(data,(e.target.checked ? 'add' : 'remove'));
    if(e.target.checked) {
      let ids = this.selectedIdsValue;
      ids.push(e.target.value);
      this.selectedIdsValue = ids;
    } else {
      this.selectedIdsValue = this.selectedIdsValue.filter((id) => id.toString() !== e.target.value);
    }
  }

  updateOnServer(formData, command) {
    formData.append("command", command);
    formData.append('params_checksum', this.paramsChecksumValue);
    Rails.ajax({
      url: this.urlValue,
      type: this.ajaxMethodValue,
      data: formData
    });
  }
}
