import { Controller } from "@hotwired/stimulus"



export default class extends Controller {
  static hasConnected = false;
  static selectedIds = [];
  static allIds = [];
  static paramsChecksum = null;

  connect() {
    /*
     * If we haven't already connected, then initialize the selectedIds and allIds
     * If the params checksum has changed, then we need to re-initialize the selectedIds and allIds
     * because we're on a new page of results.
     */
    if (!this.constructor.hasConnected || this.data.get("paramsChecksum") != this.constructor.paramsChecksum) {
      this.constructor.selectedIds = JSON.parse(this.data.get("selectedIds"));
      this.constructor.allIds = JSON.parse(this.data.get("allIds"));
      this.constructor.paramsChecksum = this.data.get("paramsChecksum");
      this.constructor.hasConnected = true;
    }

    // If all ids are selected, then tick the select all checkbox
    if (this.constructor.selectedIds.length == this.constructor.allIds.length) {
      this.dispatch('select-all-toggled', { detail: { checked: true }  });
    }

    /*
     * Mark an item as checked or not, but only if it's not the select all checkbox.
     * The select-all checkboxes have values that are not numeric. The bulk-actions controller
     * listens for the 'item-toggled' event, and if we dispatch this for the select-all checkbox,
     * the bulk actions controller will then include the select-all checkbox in its count of
     * selected items, which is bad.
     */
    const element_value = parseInt(this.element.value);
    if (!isNaN(element_value)) {
      this.element.checked = this.constructor.selectedIds.includes(parseInt(this.element.value));
      this.dispatch('item-toggled', { detail: { id: this.element.value, checked: this.element.checked }, bubbles: true } );
    }
  }

  // Called when ticking an individual checkbox (not the select all checkbox)
  toggleItem() {
    let command = "";
    if (this.element.checked) {
      this.constructor.selectedIds.push(parseInt(this.element.value));
      command = "add";
    } else {
      this.constructor.selectedIds = this.constructor.selectedIds.filter(id => id != parseInt(this.element.value));
      command = "remove";
    }

    // Dispatch 'item-toggled' event so that the bulk-actions controller can update its count of selected items
    this.dispatch('item-toggled', { detail: { id: this.element.value, checked: this.element.checked } , bubbles: true } );

    // If all ids are selected, then tick the select all checkbox, otherwise untick it
    if (this.constructor.selectedIds.length == this.constructor.allIds.length) {
      this.dispatch('select-all-toggled', { detail: { checked: true } });
    } else {
      this.dispatch('select-all-toggled', { detail: { checked: false } });
    }

    // Save selections
    this.updateSelectedIds(command, [this.element.value])
  }

  // Called when ticking the select all checkbox
  toggleAll() {
    let command = "";
    if (this.element.checked) {
      this.constructor.selectedIds = this.constructor.allIds;
      command = "set";
    } else {
      this.constructor.selectedIds = [];
      command = "remove";
    }

    // Dispatch 'all-toggled' so that each individual checkbox can be updated
    this.dispatch('all-toggled', { detail: { checked: this.element.checked }, bubbles: true });
    this.updateSelectedIds(command, this.constructor.allIds)
  }

  // Called when certain check events occur. Used to update the state of checkboxes that
  // are not directly checked, but affected by clicking others.
  handleCheckEvent(event) {
    this.element.checked = event.detail.checked;
  }

  // Save the selectedIds to the server
  updateSelectedIds(command, ids) {
    $.post('/search_selections', {
      command,
      params_checksum: this.constructor.paramsChecksum,
      ids
    }
    );
  }
}