import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import Turbolinks from 'turbolinks';

const bulkActionCriteria = new class BulkActionCriteria {
  constructor(scope) {
    this.allSelected = false;
    this.ids = {};
  }

  addID(id, checked) {
    this.ids[id] = checked;
  }

  clear() {
    this.allSelected = false;
    this.ids = {};
  }

  except() {
    return Object.keys(
      Object.fromEntries(
        Object.entries(this.ids)
          .filter(([key, value]) => value == false)
      )
    ) || [];
  }

  only() {
    return Object.keys(
      Object.fromEntries(
        Object.entries(this.ids)
          .filter(([key, value]) => value == true)
      )
    );
  }

  formData() {
    let data = new FormData();

    if (this.allSelected) {
      this.except().forEach(function (id) {
        data.append("except[]", id);
      });
    } else {
      this.only().forEach(function (id) {
        data.append("only[]", id);
      });
    }

    return data;
  }

  actionCount(totalRecordCount) {
    let count = 0;

    if (this.allSelected) {
      count = totalRecordCount - this.except().length;
    } else {
      count = this.only().length;
    }

    return count;
  }
}

// stimulus controller for bulk action checkbox selection
export default class extends Controller {
  static targets = [ "selectedCount", "dropdown", "selectedCountContainer", "form", "choice"];
  totalRecordCount = 0
  location = ''

  connect() {
    this.location = window.location.pathname;
    this.totalRecordCount = parseInt(this.element.dataset.totalRecordCount);
    this.toggleEnabled();
  }

  disconnect() {
    if (this.location != window.location.pathname) {
      bulkActionCriteria.clear();
    }
  }

  allToggled(event) {
    let count = 0;

    bulkActionCriteria.clear();

    if (event.detail.checked) {
      bulkActionCriteria.allSelected = true;
    } else {
      this.recordCount = 0;
    }

    this.toggleEnabled();
  }

  itemToggled(event) {
    let count = 0;

    bulkActionCriteria.addID(event.detail.id, event.detail.checked);

    this.selectedCountTarget.innerHTML = bulkActionCriteria.actionCount(this.totalRecordCount);
    this.toggleEnabled();
  }

  toggleEnabled() {
    let className = "disabled";
    let actionCount = bulkActionCriteria.actionCount(this.totalRecordCount);

    this.selectedCountTarget.innerHTML = actionCount;

    if (actionCount >= 1) {
      this.dropdownTarget.classList.remove(className);
      this.selectedCountContainerTarget.classList.remove(className);
    } else {
      this.dropdownTarget.classList.add(className);
      this.selectedCountContainerTarget.classList.add(className);
    }
  }

  choiceClicked(event) {
    event.stopImmediatePropagation();
    event.preventDefault();

    this.makeRequest(event.currentTarget);
  }

  makeRequest(current_target) {
    let url = current_target.getAttribute("href");
    let method = current_target.dataset.method;
    // doNotClearCriteria is optional, which is used to prevent clearing the criteria after the request is made with the response
    // as the bulk action may need to be re-run
    let doNotClearCriteria = current_target.dataset.doNotClearCriteria; // optional

    Rails.ajax({
      dataType: "script",
      type: method,
      url: url,
      data: bulkActionCriteria.formData(),
      success: function(data) {
        if (doNotClearCriteria === undefined) { // checking for undefined as other developers may use the data attribute without a value
          bulkActionCriteria.clear()
        }
      }
    })
  }
}
