import { Controller } from "@hotwired/stimulus"

// This controller is used to open the links in the rich text editor in a new tab
// as ActionText Trix does not open links in new tab.
export default class extends Controller {
  connect() {
    this.element.querySelectorAll('a').forEach(function(link) {
      if (link.host !== window.location.host) {
        link.target = "_blank"
      }
    })
  }
}
