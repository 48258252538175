// Based on https://github.com/justalever/drag_and_drop_active_storage
// Here's an example of what the haml looks like...
//  .dropzone.dropzone-default.dz-clickable{"data-controller" => "dropzone", "data-dropzone-max-file-size" => "10", "data-dropzone-max-files" => "10",  "data-accepted-files" => "image/*"}
//    = f.file_field :images, multiple: true, direct_upload: true, data: { target: 'dropzone.input' }
//    .dropzone-msg.dz-message.needsclick.text-gray-600
//      %h3.dropzone-msg-title= t("dropzone.title")
//      %span.dropzone-msg-desc.text-sm= t("dropzone.description", size_limit: '10 MB', allowed_file_types: 'png, jpg' )

import Dropzone from "dropzone";
import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage";
import {
  getMetaValue,
  findElement,
  removeElement,
  insertAfter
} from "helpers";

// stimulus controller to plugin dropzone
export default class extends Controller {
  static targets = ["input"];

  // initialize dropzone plugin on component load
  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    this.loadExistingFiles();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  // disable and hide file input
  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  // bind rails direct upload events to dropzone events
  bindEvents() {
    const submitButton = findElement(this.inputTarget.form, "button[type='submit']");

    this.dropZone.on("addedfile", file => {
      // disable submit button on file upload
      submitButton.disabled = true
      setTimeout(() => {
        // skip direct upload while displaying uploaded files on form rerender.
        // newly added files will have status 'added', existing mock files will have status 'success'
        file.accepted && file.status == "added" && createDirectUploadController(this, file).start();
      }, 500);
    });

    this.dropZone.on("removedfile", file => {
      if(file.controller){
        removeElement(file.controller.hiddenInput);
      }
      else if(file.element) {
        removeElement(file.element)
      }
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on("queuecomplete", file => {
      // enable submit button after uploading all files
      submitButton.disabled = false
    });
  }

  // load existing non persisted files into dropzone
  loadExistingFiles() {
    this.dropZone.element.querySelectorAll("input[data-url][name='item[images][]']").forEach( (element) => {
      let mockFile = {
          name: element.dataset.name,
          size: element.dataset.size,
          accepted: true , // to add mock file in accepted file queue
          status: 'success', // to skip file reupload to server
          kind: element.dataset.kind, // to skip preview for non images files, set kind as `file`
          element: element
        };
      const crossOrigin = 'anonymous'
      this.dropZone.displayExistingFile(mockFile, element.dataset.url, null, crossOrigin);
    })
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  // if data-max-file-size attribute not present in mockup
  // sets max file size limit to 256MB
  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
}

// handles rails file driect upload
class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  // upload file to the server and assign signed_id to hidden input field
  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  // create hidden input to track and submit uploaded file signed_id's
  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  // display progressbar on file upload
  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  // calculate upload progress percent
  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false
  });
}
