import { Controller } from "@hotwired/stimulus";

const MILISECONDS_PER_DAY = 1000 * 60 * 60 * 24;
const MILISECONDS_PER_HOUR = 1000 * 60 * 60;
const MILISECONDS_PER_MINUTE = 1000 * 60;

export default class extends Controller {

  static values = {
    endAt: String,
    startAt: String,
    refreshInterval: { type: Number, default: 1000 },
    auctionClosedMessage: { type: String, default: "<h6>Auction has ended</h6>" },
    auctionOpenMessage: { type: String, default: "Time left to bid" },
    auctionNotOpenMessage: { type: String, default: "Bidding will start" }
  }

  static targets = ["days", "minutes", "hours", "seconds", "status", "timerCaption"]

  connect(){
    this.endDateTime = this.calculateDate(this.endAtValue).getTime();
    this.startDateTime = this.calculateDate(this.startAtValue).getTime();

    // refreshes the timer every 1 second and updates the view
    this.timer = setInterval(() => {
      this.countDownTimer();
    }, this.refreshIntervalValue)
  }

  disconnect(){
    this.stopTimer();
  }

  stopTimer(){
    if(this.timer){
      clearInterval(this.timer);
    }
  }

  countDownTimer(){
    let timeLeftToStart = this.timeDifference(this.startDateTime);

    // not open: auction start date is in the future
    if(timeLeftToStart > 0){
      this.updateTimer(timeLeftToStart, this.auctionNotOpenMessageValue);
      return;
    }
    
    let timeLeftToEnd = this.timeDifference(this.endDateTime);

    // closed: auction end date is in the past
    if(timeLeftToEnd < 0){
      this.statusTarget.innerHTML = this.auctionClosedMessageValue;
      this.timerCaptionTarget.remove();
      this.stopTimer();
      return;
    }

    // open: auction start date is in the past, end date is in the future
    this.updateTimer(timeLeftToEnd, this.auctionOpenMessageValue);
  }

  updateTimer(timeLeft, message){
    let days = Math.floor(timeLeft / MILISECONDS_PER_DAY) // conversion miliseconds on days
    let hours = Math.floor((timeLeft % MILISECONDS_PER_DAY) / MILISECONDS_PER_HOUR) // conversion miliseconds on hours
    let minutes = Math.floor((timeLeft % MILISECONDS_PER_HOUR) / MILISECONDS_PER_MINUTE) // conversion miliseconds on minutes
    let seconds = Math.floor((timeLeft % MILISECONDS_PER_MINUTE) / 1000) // conversion miliseconds on seconds

    this.daysTarget.textContent = days;
    this.hoursTarget.textContent = hours;
    this.minutesTarget.textContent = minutes;
    this.secondsTarget.textContent = seconds;
    this.timerCaptionTarget.textContent = message;
  }

  timeDifference(givenTime){
    return givenTime - new Date().getTime();
  }

  // IE, Safari and Firefox do not support date in the format “YYYY-MM-DD”
  // Thus Date() does not work here
  // Using moment to solve this problem
  calculateDate(givenDateTime){
    let calculatedDate = moment.utc(givenDateTime, 'YYYY-MM-DD HH:mm:ss').toDate();
    return calculatedDate;
  }
}