import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fairMarketValue", "priceless", "treatAsDonation", "caption", "treatAsDonationLabel"];

  connect() {
    this.isPurchased = this.treatAsDonationTarget.dataset.purchasedStatus == "true"; // Initialize the variable

    // Load the initial state of the treatAsDonationTarget from the rendered HTML
    this.initialTreatAsDonationState = this.treatAsDonationTarget.checked;

    if (this.isPurchased) {
      this.handlePurchasedItem();
    } else {
      this.handleUnpurchasedItem();
    }
  }

  handleUnpurchasedItem() {
    this.checkFairMarketAndPricelessValues();
    // Restore the initial state of the treatAsDonationTarget
    this.treatAsDonationTarget.checked = this.initialTreatAsDonationState;
  }

  // Toggle is disabled if the item is paid or refunded
  handlePurchasedItem() {
    this.disableDonationElements();
    this.captionTarget.innerHTML = "Donation status can't be changed once an item has been purchased.";
  }

  checkFairMarketAndPricelessValues() {
    if (this.isPurchased) {
      return; // Do not change the toggle or caption if the item is purchased
    }

    const fmvValue = parseFloat(this.fairMarketValueTarget.value);
    const isPriceless = this.pricelessTarget.checked;

    if (isNaN(fmvValue)) return; // Check if the value is a number

    if (fmvValue == 0 && !isPriceless) {
      this.enableDonationElements();
      this.captionTarget.innerHTML = "This item is available as a donation.";
    } else {
      // Only modify the checkbox value if FMV is not zero, item is not purchased, and item is not priceless
      if (!this.isPurchased) {
        this.treatAsDonationTarget.checked = false;
      }
      this.disableDonationElements();
      this.captionTarget.innerHTML = "A 'donation' assumes that no goods or services were received. To mark this as a donation, the fair market value should be $0.";
    }
  }

  enableDonationElements() {
    this.treatAsDonationTarget.disabled = false;
    this.treatAsDonationLabelTarget.removeAttribute("disabled");
  }

  disableDonationElements() {
    this.treatAsDonationTarget.disabled = true;
    this.treatAsDonationLabelTarget.setAttribute("disabled", "true");
  }
}
