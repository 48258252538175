import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"; // This is needed to fire the Rails submit event

// This controller is used to debounce the search input in the bidder index view
// It's used in the view like this:
// = text_field_tag "filter_criteria[keyword]", params.dig(:filter_criteria, :keyword), data: { action: "input-> bidder-search#search", ... }...
export default class extends Controller {
  static targets = ["form"];

  // This is the method that gets called when the input changes (see the data-action attribute in the view)
  search() {
    clearTimeout(this.timeout); // Clear the timeout so we don't end up with dupes.
    this.timeout = setTimeout(() => {
      Rails.fire(this.formTarget, 'submit'); /// Fire the Rails submit event
    }, 200); // 200ms is the debounce time
  }
}
