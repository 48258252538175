import { Application } from "@hotwired/stimulus"
// definitionsFromContext helper method will exract identifier from the file names.
// i.e `dropzone` will be identifier for dropzone_controller.js
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import CharacterCounter from 'stimulus-character-counter'

const application = Application.start()
application.register('character-counter', CharacterCounter)

// Crawl controllers directory and fetch all js file ending with _controller.js inside any subdirectory.
const context = require.context("controllers", true, /_controller\.js$/)
// application.load will import and register controller component
// i.e dropzone_controller.js will be imported and registered as `dropzone`
// import DropzoneController from "./dropzone_controller"
// application.register("dropzone", DropzoneController)
application.load(definitionsFromContext(context))

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

export { application }
